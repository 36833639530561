
import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getArticleDetail, getArticleDes, addToCart, updateCartItem, getCarts } from '../redux/action';
import { Col, Container, Row, Button, Form ,Card} from 'react-bootstrap';
import { CardActions, CardContent, Typography, Box } from '@mui/material';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import Footer from '../components/Footer';
const CardServices = React.lazy(() => import('../components/card/CardServices'));

const DetailArticle = () => {
  function importAll(r) {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace('./', '')] = r(item));
    });
    return images;
  }

  const images = importAll(require.context('../../public/images', false, /\.(png|jpe?g|svg)$/));
  const [isOpen, setIsOpen] = useState(false);
  const [photoSrc, setPhotoSrc] = useState('');
  const { client } = useSelector(state => state.client);
  const [codcli, setCodecli] = useState(null);
  const { article, description, loading } = useSelector(state => state.article);
  const { cartItems } = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const { codart } = useParams();

  useEffect(() => {
    if (client) {
      setCodecli(client.codcli);
    }

    dispatch(getArticleDetail(codart));
    dispatch(getArticleDes(codart));
    dispatch(getCarts(codcli));
  }, [dispatch, codart, codcli,photoSrc]);

  const [qte, setQte] = useState(1);
  const [sousTT, setSousTT] = useState(0);
  const [PUDTTC, setPUDTTC] = useState(0);
  const [stkfin, setStkfin] = useState(0);
  const [alertMsg, setAlertMsg] = useState('');

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      const currentItem = cartItems.find(item => item.codart === codart);
      if (currentItem) {
        setQte(currentItem.QTE);
        setSousTT(currentItem.SousTT);
      }
    }
  }, [cartItems, codart]);
  // useEffect(() => {
  //   if (article?.Photo) {
  //     console.log('Binary photo data length:', article.Photo);
  //     const base64String = btoa(
  //       String.fromCharCode(...new Uint8Array(article.Photo))
  //     );
  //     setPhotoSrc(`data:image/jpeg;base64,${base64String}`);
  //   } else {
  //     console.log('No photo data available');
  //   }
  // }, [article]);
  
  useEffect(() => {
    if (article?.Photo) {
      const base64String = btoa(String.fromCharCode(...new Uint8Array(article.Photo.data)));
      setPhotoSrc(`data:image/jpeg;base64,${base64String}`);
    }
    article?.forEach(item => {
      console.log(`Code: ${item.codart}, Description: ${item.desart}`);
      // Utilisez l'état pour stocker les informations de chaque article.
      setPUDTTC(item.PUDTTC); // Cela va écraser l'état à chaque itération
      setSousTT(item.PUDTTC); // Idem ici
      setStkfin(item.stkfin); // Idem ici
    });
  
}, [article]);
  //   if (article) {
  //     setPUDTTC(article.PUDTTC);
  //     setSousTT(article.PUDTTC);
  //     setStkfin(article.stkfin);
  //   }
  // }, [article]);

  const addToCartHandler = () => {
   
    if (qte > stkfin) {
      setAlertMsg('La quantité demandée dépasse le stock disponible');
    } else {
      dispatch(addToCart(codart, codcli, qte, sousTT, PUDTTC));
      setAlertMsg("L'article a été ajouté au panier");
    }
  };
  const stockStyle = {
    color: stkfin > 1 ? 'green' : 'red'
  };
  const increment = () => {
    if (stkfin > qte) {
      const newQty = qte + 0.5;
      const newSousTT = newQty * PUDTTC;
      setQte(newQty);
      setSousTT(newSousTT);
      dispatch(updateCartItem(codart, codcli, newQty, newSousTT, PUDTTC));
      dispatch(getCarts(codcli));
    } else {
      setAlertMsg('La quantité demandée dépasse le stock disponible');
    }
  };

  const decrement = () => {
    if (qte > 1) {
      const newQty = qte - 0.5;
      const newSousTT = newQty * PUDTTC;
      setQte(newQty);
      setSousTT(newSousTT);
      dispatch(updateCartItem(codart, codcli, newQty, newSousTT, PUDTTC));
      dispatch(getCarts(codcli));
    }
  };

  const goSignIn = () => alert("⚠️ Veuillez d'abord vous connecter");
   console.log('desgart',article)

  // article.forEach(item => {
  //   console.log(`Description: ${item.desart}, photo: ${item.Photo}`);
  // });
  // const articleDetails = article.map((item, index) => (
  //   <div key={index}>
  //     <span>Code: {item.codart}</span>
  //     <span>Description: {item.desart}</span>
  //   </div>
   
  // ));
  
  return (
    
    <div>
        {article && article.map((item, index) => (
    <div key={index}>
      <div className="cart p-4 text-white mb-3">
        <h1 className="display-6 border-bottom">Detail Article</h1>
      </div>

      <Container fluid className="" >
        <Row>
          <Col md={8}>
            <Row className="mb-5">
              {/* <Col md={4} className="text-center">
                <img
                  src={photoSrc}
                  className="img-fluid mb-3"
                  alt={article?.desart}
                  style={{
                    height: '300px',
                    width: '300px',
                    marginTop: '20px',
                    borderRadius: '10px',
                    marginLeft:'20px'
                  }}
                />
              </Col>
              */}
               <Col md={4} className="text-center">
                <div className="product-gallery">
                  <a href="#" onClick={(e) => { e.preventDefault(); setIsOpen(true); }}>
                    <img
                      // src={photoSrc}
                      src={`data:image/*;base64,${item.Photo}`}
                      className="img-fluid mb-3 zoom"
                      alt={article?.desart}
                      style={{
                        height: '300px',
                        width: '300px',
                        marginTop: '20px',
                        borderRadius: '10px',
                        cursor: 'pointer'
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col md={8}  style={{marginLeft:'0px'
                  }}>
                  
             <h2 className="h2  text-start centre">{item.desart}</h2>
                {/* <span className="badge bg-success me-2 " style={{marginLeft:"-400px"}}>NOUVELLE</span> */}
                <div className="mb-3">
                  <i className="bi bi-star-fill text-warning me-1" />
                  <i className="bi bi-star-fill text-warning me-1" />
                  <i className="bi bi-star-fill text-warning me-1" />
                  <i className="bi bi-star-fill text-warning me-1" />
                  <i className="bi bi-star-fill text-secondary me-1" />
                </div>
                <dl className="row small mb-3 text-start" style={{ fontSize: '1.2rem' }}>
                  <dt className="col-sm-3">Disponibilité:</dt>
                  <dd className="col-sm-9">
                  <span style={stockStyle}>
                  {stkfin > 1 ? 'En stock' : 'Hors stock'}
                </span>
                 
                  </dd>
                  <dt className="col-sm-3">Couleur:</dt>
                  <dd className="col-sm-9">
                    <Box
                      component="span"
                      sx={{
                        display: 'inline-block',
                        width: 16,
                        height: 16,
                        borderRadius: '50%',
                        backgroundColor: item.chdec,
                        ml: 1,
                      }}
                    />
                  </dd>
                 
                  <dt className="col-sm-3">Prix:</dt>
                  <dd className="col-sm-9">{PUDTTC}DT</dd>
                </dl>


                <Row className="mb-3">
                  <Col md={4}  >
                    <label htmlFor="quantity" style={{ fontSize: '20px', fontWeight: '600', }}>Quantité:</label>
                    <div className="d-flex mb-4">
                      <Button
                        style={{ fontSize: '20px', fontWeight: '600', color: 'red' }}
                        className="btn btn-light border px-2"
                        onClick={decrement}
                      >
                        <i className="fa fa-minus-circle"></i>
                      </Button>
                      <Form.Control
                        value={qte}
                        className="text-center border px-2"
                        onChange={(e) => setQte(e.target.value)}
                        style={{ fontSize: '20px', fontWeight: '600' }}
                      />
                      <Button
                        className="btn btn-light border px-2"
                        onClick={increment}
                        style={{ fontSize: '20px', fontWeight: '600', color: 'green' }}
                      >
                        <i className="fa fa-plus-circle"></i>
                      </Button>
                    </div>
                  </Col>
                  <Col md={8} style={{ textAlign:"start"}}>
                    <label htmlFor="Sous Total"  style={{ fontSize: '20px', fontWeight: '600', }}>Sous total:</label>
                    <div style={{ fontSize: '20px', fontWeight: '600',marginLeft:"20px", color:"green"}}>{sousTT} DT</div>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={5} >
                    {client ? (
                       <Link to="/cart">
                      <Button variant="success" onClick={addToCartHandler} disabled={article.stkfin < 1} style={{ fontSize:'20px' }}>
                        <FontAwesomeIcon icon={faShoppingCart} style={{ marginRight: '8px' }} />
                        Ajouter au Panier
                      </Button>
                      </Link>
                    ) : (
                      <Link to="/login">
                      
                      <Button variant="success" onClick={goSignIn} style={{ fontSize:'20px' }}>
                        Ajouter au Panier <FontAwesomeIcon icon={faShoppingCart} className="icon" />

                      </Button>
                      </Link>
                    )}
                  </Col>
                  {alertMsg && (
                    <Typography variant="body1" color="error">
                      {alertMsg}
                    </Typography>
                  )}
                </Row>
               
                </Col> 
                <dl className="row small mb-3 text-start" style={{ fontSize: '1.2rem',marginTop:'10px',marginLeft:'50px' }}>
                   <dt className="col-sm-9">Description:</dt>
                  <dd className="col-sm-9">{item.memolig || 'No Description data '}</dd>
                  </dl>
           </Row>
          </Col>
          <Col md={4}>
            <Suspense fallback={<div>Loading...</div>}>
              <CardServices />
            </Suspense>
          </Col>
        </Row>
      </Container>
       {/* Lightbox */}
       {isOpen && (
        <Lightbox
          // mainSrc={photoSrc}
          mainSrc={ `data:image/*;base64,${item.Photo}`}
         
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
          <Footer />
          </div>))}
    </div>
  );
};

export default DetailArticle;


// import React, { useEffect, useState, Suspense } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useParams, useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import { getArticleDetail, getArticleDes, addToCart, updateCartItem, getCarts } from '../redux/action';
// import { Col, Container, Row, Button, Form, Card } from 'react-bootstrap';
// import { CardActions, CardContent, Typography, Box } from '@mui/material';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
// import 'react-image-lightbox/style.css';
// import Lightbox from 'react-image-lightbox';
// import Footer from '../components/Footer';
// const CardServices = React.lazy(() => import('../components/card/CardServices'));

// const DetailArticle = () => {
//   const navigate = useNavigate(); // Utilisation du hook useNavigate pour la redirection
//   const { client } = useSelector(state => state.client);
//   const { article, description, loading } = useSelector(state => state.article);
//   const { cartItems } = useSelector(state => state.cart);
//   const dispatch = useDispatch();
//   const { codart } = useParams();
//   const [codcli, setCodecli] = useState(null);
//   const [photoSrc, setPhotoSrc] = useState('');
//   const [qte, setQte] = useState(0.5);
//   const [sousTT, setSousTT] = useState(0);
//   const [PUDTTC, setPUDTTC] = useState(0);
//   const [stkfin, setStkfin] = useState(0);
//   const [alertMsg, setAlertMsg] = useState('');
//   const [isOpen, setIsOpen] = useState(false);

//   // Gestion du client (code client)
//   useEffect(() => {
//     if (client) {
//       setCodecli(client.codcli);
//     }
//   }, [client]);

//   // Récupération des détails de l'article
//   useEffect(() => {
//     if (codart) {
//       dispatch(getArticleDetail(codart));
//       dispatch(getArticleDes(codart));
//       if (codcli) {
//         dispatch(getCarts(codcli));
//       }
//     }
//   }, [dispatch, codart, codcli]);

//   // Vérification si l'article existe après la récupération des détails
//   // useEffect(() => {
//   //   if (!article || article.length === 0) {
//   //     navigate('/'); // Redirige vers la page d'accueil si l'article n'existe pas
//   //   }
//   // }, [article, navigate]);

//   // Initialisation de la quantité et sous-total depuis le panier
//   useEffect(() => {
//     if (cartItems && cartItems.length > 0) {
//       const currentItem = cartItems.find(item => item.codart === codart);
//       if (currentItem) {
//         setQte(currentItem.QTE);
//         setSousTT(currentItem.SousTT);
//       }
//     }
//   }, [cartItems, codart]);

//   // Traitement de l'image
//   useEffect(() => {
//     if (article?.[0]?.Photo) {
//       const base64String = btoa(String.fromCharCode(...new Uint8Array(article[0].Photo.data)));
//       setPhotoSrc(`data:image/jpeg;base64,${base64String}`);
//       const item = article[0];
//       setPUDTTC(item.PUDTTC);
//       setSousTT(item.PUDTTC);
//       setStkfin(item.stkfin);
//     }
//   }, [article]);

//   const addToCartHandler = () => {
//     if (qte > stkfin) {
//       setAlertMsg('La quantité demandée dépasse le stock disponible');
//     } else {
//       dispatch(addToCart(codart, codcli, qte, sousTT, PUDTTC));
//       setAlertMsg("L'article a été ajouté au panier");
//     }
//   };

//   const stockStyle = {
//     color: stkfin > 1 ? 'green' : 'red'
//   };

//   const increment = () => {
//     if (stkfin > qte) {
//       const newQty = qte + 0.5;
//       const newSousTT = newQty * PUDTTC;
//       setQte(newQty);
//       setSousTT(newSousTT);
//       dispatch(updateCartItem(codart, codcli, newQty, newSousTT, PUDTTC));
//       dispatch(getCarts(codcli));
//     } else {
//       setAlertMsg('La quantité demandée dépasse le stock disponible');
//     }
//   };

//   const decrement = () => {
//     if (qte > 1) {
//       const newQty = qte - 0.5;
//       const newSousTT = newQty * PUDTTC;
//       setQte(newQty);
//       setSousTT(newSousTT);
//       dispatch(updateCartItem(codart, codcli, newQty, newSousTT, PUDTTC));
//       dispatch(getCarts(codcli));
//     }
//   };

//   const goSignIn = () => alert("⚠️ Veuillez d'abord vous connecter");

//   return (
//     <div>
//         {article && article.map((item, index) => (
//     <div key={index}>
//       <div className="cart p-4 text-white mb-3">
//         <h1 className="display-6 border-bottom">Detail Article</h1>
//       </div>

//       <Container fluid className="" >
//         <Row>
//           <Col md={8}>
//             <Row className="mb-5">
//               {/* <Col md={4} className="text-center">
//                 <img
//                   src={photoSrc}
//                   className="img-fluid mb-3"
//                   alt={article?.desart}
//                   style={{
//                     height: '300px',
//                     width: '300px',
//                     marginTop: '20px',
//                     borderRadius: '10px',
//                     marginLeft:'20px'
//                   }}
//                 />
//               </Col>
//               */}
//                <Col md={4} className="text-center">
//                 <div className="product-gallery">
//                   <a href="#" onClick={(e) => { e.preventDefault(); setIsOpen(true); }}>
//                     <img
//                       // src={photoSrc}
//                       src={`data:image/*;base64,${item.Photo}`}
//                       className="img-fluid mb-3 zoom"
//                       alt={article?.desart}
//                       style={{
//                         height: '300px',
//                         width: '300px',
//                         marginTop: '20px',
//                         borderRadius: '10px',
//                         cursor: 'pointer'
//                       }}
//                     />
//                   </a>
//                 </div>
//               </Col>
//               <Col md={8}  style={{marginLeft:'0px'
//                   }}>
                  
//              <h2 className="h2  text-start centre">{item.desart}</h2>
//                 {/* <span className="badge bg-success me-2 " style={{marginLeft:"-400px"}}>NOUVELLE</span> */}
//                 <div className="mb-3">
//                   <i className="bi bi-star-fill text-warning me-1" />
//                   <i className="bi bi-star-fill text-warning me-1" />
//                   <i className="bi bi-star-fill text-warning me-1" />
//                   <i className="bi bi-star-fill text-warning me-1" />
//                   <i className="bi bi-star-fill text-secondary me-1" />
//                 </div>
//                 <dl className="row small mb-3 text-start" style={{ fontSize: '1.2rem' }}>
//                   <dt className="col-sm-3">Disponibilité:</dt>
//                   <dd className="col-sm-9">
//                   <span style={stockStyle}>
//                   {stkfin > 1 ? 'En stock' : 'Hors stock'}
//                 </span>
                 
//                   </dd>
//                   <dt className="col-sm-3">Couleur:</dt>
//                   <dd className="col-sm-9">
//                     <Box
//                       component="span"
//                       sx={{
//                         display: 'inline-block',
//                         width: 16,
//                         height: 16,
//                         borderRadius: '50%',
//                         backgroundColor: item.chdec,
//                         ml: 1,
//                       }}
//                     />
//                   </dd>
                 
//                   <dt className="col-sm-3">Prix:</dt>
//                   <dd className="col-sm-9">{item.PUDTTC}DT</dd>
//                 </dl>


//                 <Row className="mb-3">
//                   <Col md={4}  >
//                     <label htmlFor="quantity" style={{ fontSize: '20px', fontWeight: '600', }}>Quantité:</label>
//                     <div className="d-flex mb-4">
//                       <Button
//                         style={{ fontSize: '20px', fontWeight: '600', color: 'red' }}
//                         className="btn btn-light border px-2"
//                         onClick={decrement}
//                       >
//                         <i className="fa fa-minus-circle"></i>
//                       </Button>
//                       <Form.Control
//                         value={qte}
//                         className="text-center border px-2"
//                         onChange={(e) => setQte(e.target.value)}
//                         style={{ fontSize: '20px', fontWeight: '600' }}
//                       />
//                       <Button
//                         className="btn btn-light border px-2"
//                         onClick={increment}
//                         style={{ fontSize: '20px', fontWeight: '600', color: 'green' }}
//                       >
//                         <i className="fa fa-plus-circle"></i>
//                       </Button>
//                     </div>
//                   </Col>
//                   <Col md={8} style={{ textAlign:"start"}}>
//                     <label htmlFor="Sous Total"  style={{ fontSize: '20px', fontWeight: '600', }}>Sous total:</label>
//                     <div style={{ fontSize: '20px', fontWeight: '600',marginLeft:"20px", color:"green"}}>{sousTT} DT</div>
//                   </Col>
//                 </Row>
//                 <Row className="mb-3">
//                   <Col md={5} >
//                     {client ? (
//                        <Link to="/cart">
//                       <Button variant="success" onClick={addToCartHandler} disabled={article.stkfin < 1} style={{ fontSize:'20px' }}>
//                         <FontAwesomeIcon icon={faShoppingCart} style={{ marginRight: '8px' }} />
//                         Ajouter au Panier
//                       </Button>
//                       </Link>
//                     ) : (
//                       <Link to="/login">
                      
//                       <Button variant="success" onClick={goSignIn} style={{ fontSize:'20px' }}>
//                         Ajouter au Panier <FontAwesomeIcon icon={faShoppingCart} className="icon" />

//                       </Button>
//                       </Link>
//                     )}
//                   </Col>
//                   {alertMsg && (
//                     <Typography variant="body1" color="error">
//                       {alertMsg}
//                     </Typography>
//                   )}
//                 </Row>
               
//                 </Col> 
//                 <dl className="row small mb-3 text-start" style={{ fontSize: '1.2rem',marginTop:'10px',marginLeft:'50px' }}>
//                    <dt className="col-sm-9">Description:</dt>
//                   <dd className="col-sm-9">{item.memolig || 'No Description data '}</dd>
//                   </dl>
//            </Row>
//           </Col>
//           <Col md={4}>
//             <Suspense fallback={<div>Loading...</div>}>
//               <CardServices />
//             </Suspense>
//           </Col>
//         </Row>
//       </Container>
//        {/* Lightbox */}
//        {isOpen && (
//         <Lightbox
//           // mainSrc={photoSrc}
//           mainSrc={ `data:image/*;base64,${item.Photo}`}
         
//           onCloseRequest={() => setIsOpen(false)}
//         />
//       )}
//           <Footer />
//           </div>))}
//     </div>
//   );
// };

// export default DetailArticle;
