// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { getAllFamProducts, getArticlesByFamily, getBySousFamArt, getArticlesByFamArtAndSousFam } from '../redux/action';
// import { Container, Nav } from 'react-bootstrap';
// import "./ListFamProd.css"; // Ensure you import your custom CSS file

// const ListFamProd = () => {
//     const dispatch = useDispatch();
//     const { families, sousfamilies } = useSelector(state => state.article);
//     const [selectedFamily, setSelectedFamily] = useState(null);

//     useEffect(() => {
//         dispatch(getAllFamProducts());
//     }, [dispatch]);

//     const handleFamilyClick = (familyCode) => {
//         dispatch(getArticlesByFamily(familyCode));
//         dispatch(getBySousFamArt(familyCode));
//         setSelectedFamily(familyCode);
//     };

//     const handleSousFamilyClick = (familyCode, sousFamilyCode) => {
//         dispatch(getArticlesByFamArtAndSousFam(familyCode, sousFamilyCode));
//     };

//     return (
//         <Container>
//                 <Nav  justify variant="tabs" className="p-3 mb-2 bg-secondary text-white hover-bg-yellow nav" style={{marginTop:"20px"}} >
                    
//                     {families.map((family, index) => (
//                         <Nav.Item key={index}>
//                             <Nav.Link onClick={() => handleFamilyClick(family.code)} className="text-white font-weight-bold hover-bg-yellow">
//                                 {family.lib}
//                             </Nav.Link>
//                         </Nav.Item>
//                     ))}
//                 </Nav>
//                 {selectedFamily && (
//                     <div>
//                         <Nav justify variant="tabs" className="p-3 mb-2 bg-secondary text-white hover-bg-yellow">
//                             {sousfamilies.map((sousfamily, index) => (
//                                 <Nav.Item key={index}>
//                                     <Nav.Link onClick={() => handleSousFamilyClick(sousfamily.chdec, sousfamily.lib)} className="text-white font-weight-bold hover-bg-yellow">
//                                         {sousfamily.lib}
//                                     </Nav.Link>
//                                 </Nav.Item>
//                             ))}
//                         </Nav>
//                     </div>
//                 )}
           
//         </Container>
//     );
// };

// export default ListFamProd;
//////////////////////////////////////////////////////////////////////////////////202444444444444444///////////////////////////////////////////

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllFamProducts, getArticlesByFamily, getBySousFamArt, getArticlesByFamArtAndSousFam } from '../../redux/action';
import { Container, Nav } from 'react-bootstrap';
import "./ListFamProd.css";
import { Carousel, Col, Row } from 'react-bootstrap';
import Footer from '../Footer';
import { FaShippingFast, FaGift, FaHeadset, FaTags, FaCreditCard } from 'react-icons/fa';
 import { useNavigate } from 'react-router-dom'; // Importer useNavigate
const ListFamProd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialiser useNavigate
    const { families, sousfamilies } = useSelector(state => state.article);
    const [selectedFamily, setSelectedFamily] = useState(null);
    const [visibleFamilies, setVisibleFamilies] = useState([]);
    const [visibleSousFamilies, setVisibleSousFamilies] = useState([]);
    const [familyStartIndex, setFamilyStartIndex] = useState(0);
    const [sousFamilyStartIndex, setSousFamilyStartIndex] = useState(0);

    useEffect(() => {
        dispatch(getAllFamProducts());
    }, [dispatch]);

    useEffect(() => {
        setVisibleFamilies(families.slice(0, 5));
    }, [families]);

    useEffect(() => {
        if (selectedFamily) {
            setVisibleSousFamilies(sousfamilies.slice(0, 5));
        }
    }, [sousfamilies, selectedFamily]);

    const handleFamilyClick = (familyCode) => {
        dispatch(getArticlesByFamily(familyCode));
        dispatch(getBySousFamArt(familyCode));
        setSelectedFamily(familyCode);
        setSousFamilyStartIndex(0);
        navigate(`/getArticlesByFamily/${familyCode}`);
    };

    const handleSousFamilyClick1 = (familyCode, sousFamilyCode) => {
        dispatch(getArticlesByFamArtAndSousFam(familyCode, sousFamilyCode));


    };
    const handleSousFamilyClick = (familyCode, sousFamilyCode) => {
                dispatch(getArticlesByFamArtAndSousFam(familyCode, sousFamilyCode));
                navigate(`/getArticlesByFamily/${familyCode}/getBySousFamArt/${sousFamilyCode}`);
            };
    const handleNextFamily = () => {
        const newStartIndex = familyStartIndex + 5;
        if (newStartIndex < families.length) {
            setFamilyStartIndex(newStartIndex);
            setVisibleFamilies(families.slice(newStartIndex, newStartIndex + 5));
        }
    };

    const handlePrevFamily = () => {
        const newStartIndex = familyStartIndex - 5;
        if (newStartIndex >= 0) {
            setFamilyStartIndex(newStartIndex);
            setVisibleFamilies(families.slice(newStartIndex, newStartIndex + 5));
        }
    };

    const handleNextSousFamily = () => {
        const newStartIndex = sousFamilyStartIndex + 5;
        if (newStartIndex < sousfamilies.length) {
            setSousFamilyStartIndex(newStartIndex);
            setVisibleSousFamilies(sousfamilies.slice(newStartIndex, newStartIndex + 5));
        }
    };

    const handlePrevSousFamily = () => {
        const newStartIndex = sousFamilyStartIndex - 5;
        if (newStartIndex >= 0) {
            setSousFamilyStartIndex(newStartIndex);
            setVisibleSousFamilies(sousfamilies.slice(newStartIndex, newStartIndex + 5));
        }
    };

    return (
        
      
        <Container>
            <div className="carousels" style={{ marginTop: '-20px' }}>
  
  <div className="header" border-radius= "500px">
<div className="header-contents">

 <h3> E-commerce quel impact sur la   société d’aujourd’hui?</h3>
 <p>Le e-commerce ou le commerce en ligne prend de plus en plus de place dans la société d’aujourd’hui. En effet, les personnes préfèrent commander en ligne de par la tranquillité et la facilité. Alors quels sont les aspects positifs et négatifs du commerce en ligne? Quel est l’impact de la pandémie du covid19 sur le e-commerce et qu’elle sera l’avenir de ce commerce?</p>
 <button > View Menu</button>

</div> 
</div>

</div>

            <Nav justify variant="tabs" className="explore-menu-list" style={{ marginTop: "20px", overflowX: "auto" }}>
                <Nav.Item>
                    <button onClick={handlePrevFamily} disabled={familyStartIndex === 0} className="btn">⟨ </button>
                </Nav.Item>
                {visibleFamilies.map((family, index) => (
                    <Nav.Item key={index}>
                        <Nav.Link onClick={() => handleFamilyClick(family.code)} className="">
                            {family.lib}
                        </Nav.Link>
                    </Nav.Item>
                ))}
                <Nav.Item>
                    <button onClick={handleNextFamily} disabled={familyStartIndex + 5 >= families.length} className="btn">⟩</button>
                </Nav.Item>
            </Nav>
            {selectedFamily && (
                <div>
                    <Nav justify variant="tabs" className="p-3 mb-2 explore-menu-list" style={{ overflowX: "auto" }}>
                        <Nav.Item>
                            <button onClick={handlePrevSousFamily} disabled={sousFamilyStartIndex === 0} className="btn">⟨ </button>
                        </Nav.Item>
                        {visibleSousFamilies.map((sousfamily, index) => (
                            <Nav.Item key={index}>
                                 <Nav.Link onClick={() => handleSousFamilyClick(sousfamily.chdec, sousfamily.lib)} className="">
                                         {sousfamily.lib}
                                   </Nav.Link>
                                {/* <Nav.Link onClick={() => handleSousFamilyClick(selectedFamily, sousfamily.chdec)} className="text-white font-weight-bold" className="text-white font-weight-bold hover-bg-yellow">>
                                    {sousfamily.lib}
                                </Nav.Link> */}
                            </Nav.Item>
                        ))}
                        <Nav.Item>
                            <button onClick={handleNextSousFamily} disabled={sousFamilyStartIndex + 5 >= sousfamilies.length} className="btn">⟩</button>
                        </Nav.Item>
                    </Nav>
                </div>
            )}



<Container className="mt-5">
                            <Row>
                              <Col className="text-center">
                                <FaShippingFast size={50} />
                                <h5>Livraison gratuite</h5>
                                <p>De toutes les commandes de plus de 200dt</p>
                              </Col>
                              <Col className="text-center">
                                <FaGift size={50} />
                                <h5>Offres surprises quotidiennes</h5>
                                <p>Économisez jusqu'à 25 % de réduction</p>
                              </Col>
                              <Col className="text-center">
                                <FaHeadset size={50} />
                                <h5>Assistance 24h/24 et 7j/7</h5>
                                <p>Achetez avec un expert</p>
                              </Col>
                              <Col className="text-center">
                                <FaTags size={50} />
                                <h5>Prix abordables</h5>
                                <p>Obtenir le prix d'usine par défaut</p>
                              </Col>
                             
                            </Row>
                          </Container>
                             {/* <Footer /> */}
        </Container>
      
    );
};

export default ListFamProd;