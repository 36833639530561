// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import CustomNavbar from './components/Navbar/CustomNavbar';
// import ListArticle from './components/ListArticle';
// import Signup from './components/Signup';
// import Login from './components/Login';
// import Profile from './components/Profile';
// import DetailArticle from './components/DetailArticle';

// import { getAllArticles } from './redux/action'; 
// import "./App.css";
// import Footer from './components/Footer';
// import CartItems from './components/CartItems';
// import Checkout from './components/Checkout';
// import ContactUs from './components/ContactUs'

// function App() {
//   const dispatch = useDispatch();
//   const articles = useSelector(state => state.article.articles);

//   const [searching, setSearching] = useState("");

//   // Handle search input change
//   const handleSearch = (searchTerm) => {
//     setSearching(searchTerm);
//   };

//   useEffect(() => {
//     dispatch(getAllArticles());
//   }, []);

//   return (
//     <>
//     <div className="App">
//       <Router>
//         <CustomNavbar searching={searching} handleSearch={handleSearch} />
        
//         <Routes>
//           <Route path="/" element={
//             <div>
//             <ListArticle articles={articles.filter(el => el.desart.toLowerCase().includes(searching.toLowerCase()))} />
//               {/* <ListArticle articles={articles} /> */}

//             </div>
//           } />
//           <Route path="/Signup" element={<Signup />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/profile" element={<Profile />} />
//           <Route path="/getArticleDetail/:codart" element={<DetailArticle />} />
//           <Route path="/cart" element={<CartItems />} />
//           <Route path="/Checkout" element={<Checkout />} />
//           <Route path="/contact" element={<ContactUs />} />

//           contact
//         </Routes>
       
//       </Router>
//     </div> 
//     {/* <Footer /> */}
//     </>
//   );
// }

// export default App;
/////////////////////////////////////////////////////////////****** */ 2024  / * **********////////////////////////////////////////////////////////////////
// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import CustomNavbar from './components/Navbar/CustomNavbar';
// import ListArticle from './components/ListArticle';
// import Signup from './components/Signup';
// import Login from './components/Login';
// import Profile from './components/Profile';
// import DetailArticle from './components/DetailArticle';
// import { getAllArticles } from './redux/action'; 
// import "./App.css";
// import Footer from './components/Footer';
// import CartItems from './components/CartItems';
// import Checkout from './components/Checkout';
// import ContactUs from './components/ContactUs';
// import ListFamProd from './components/listeFam/ListFamProd';
// import FilteredArticles from './components/FilteredArticles';
// function App() {
//   const dispatch = useDispatch();
//   const articles = useSelector(state => state.article.articles);

//   const [searching, setSearching] = useState("");
//   const [filteredArticles, setFilteredArticles] = useState(articles);

//   // Handle search input change
//   const handleSearch = (searchTerm) => {
//     setSearching(searchTerm);

//     // Manually filter the articles
//     const updatedArticles = articles.filter(article =>
//       article.desart.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredArticles(updatedArticles);
//   };

//   useEffect(() => {
//     dispatch(getAllArticles());
//   }, []);

//   // Update filteredArticles when articles change
//   useEffect(() => {
//     if (searching === "") {
//       setFilteredArticles(articles);
//     } else {
//       const updatedArticles = articles.filter(article =>
//         article.desart.toLowerCase().includes(searching.toLowerCase())
//       );
//       setFilteredArticles(updatedArticles);
//     }
//   }, [articles, searching]);

//   return (
//     <>
//       <div className="App">
//         <Router>
//           <CustomNavbar searching={searching} handleSearch={handleSearch} />
          
//           <Routes>
//             <Route path="/" element={
//               <div>
//                 <ListArticle articles={filteredArticles} />
//               </div>
//             } />
//             <Route path="/Signup" element={<Signup />} />
//             <Route path="/login" element={<Login />} />
//             <Route path="/profile" element={<Profile />} />
//             <Route path="/getArticleDetail/:codart" element={<DetailArticle />} />
//             <Route path="/cart" element={<CartItems />} />
//             <Route path="/Checkout" element={<Checkout />} />
//             <Route path="/contact" element={<ContactUs />} />
//             <Route path="/getArticlesByFamily/:famart" element={<ListFamProd />} />
            
//         <Route path="/getArticlesByFamily/:famart/getBySousFamArt/:famart"   element={
//               <div>
//                 <ListArticle articles={filteredArticles} />
//               </div>
//             }/> 
    
          
//           </Routes>
//         </Router>
//       </div>
//       {/* <Footer /> */}
//     </>
//   );
// }

// export default App;

/////////////////////////////////******************2025**********************************////////////////////////////////////////////////


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CustomNavbar from './components/Navbar/CustomNavbar';
import ListArticle from './components/ListArticle';
import Signup from './components/Signup';
import Login from './components/Login';
import Profile from './components/Profile';
import DetailArticle from './components/DetailArticle';
import { getAllArticles } from './redux/action'; 
import "./App.css";
import Footer from './components/Footer';
import CartItems from './components/CartItems';
import Checkout from './components/Checkout';
import ContactUs from './components/ContactUs';
import ListFamProd from './components/listeFam/ListFamProd';
import FilteredArticles from './components/FilteredArticles';
function App() {
  const dispatch = useDispatch();
  const articles = useSelector(state => state.article.articles);

  const [searching, setSearching] = useState("");
  const [filteredArticles, setFilteredArticles] = useState(articles);
  const [filteredArticlesfam, setFilteredArticlesfam] = useState(articles);
  const [filteredArticlessoufam, setFilteredArticlessoufam] = useState(articles);
 
  const handleSearch = (searchTerm) => {
    setSearching(searchTerm);


    const updatedArticles = articles.filter(article =>
      article.desart.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredArticles(updatedArticles);
  };

  // useEffect(() => {
  //   dispatch(getAllArticles());
  // }, []);

 


  useEffect(() => {
    if (searching === "") {
      setFilteredArticlesfam(articles);
    } else {
      const updatedArticlesfam = articles.filter(article =>
        article.famart.toLowerCase().includes(searching.toLowerCase())
      );
      setFilteredArticles(updatedArticlesfam);
    }
  }, [articles, searching]);
  // console.log( 'filteredArticlesfam',filteredArticlesfam);

  useEffect(() => {
    if (searching === "") {
      setFilteredArticlessoufam(articles);
    } else {
      const updatedArticlessoufam = articles.filter(article =>
        article.sousfam.toLowerCase().includes(searching.toLowerCase())
      );
      setFilteredArticles(updatedArticlessoufam);
    }
  }, [articles, searching]);
      console.log( 'filteredArticlesfam',filteredArticlesfam);
  return (
    <>
      <div className="App">
        <Router>
          <CustomNavbar searching={searching} handleSearch={handleSearch} />
          
          <Routes>
            <Route path="/" element={<ListFamProd />} 
            //  element={
            //   <div>
            //     <ListArticle articles={filteredArticles} />
            //   </div>
            // }
             />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/getArticleDetail/:codart" element={<DetailArticle />} />
            <Route path="/cart" element={<CartItems />} />
            <Route path="/Checkout" element={<Checkout />} />
            <Route path="/contact" element={<ContactUs />} />
       
           <Route path="/getArticlesByFamily/:famart" element={
              <div>
                <ListArticle articles={filteredArticlesfam} />
               
              </div>
            } />

   
 <Route path="/getArticlesByFamily/:famart/getBySousFamArt/:famart"   element={ 
              <div>
                <ListArticle articles={filteredArticlessoufam} />
              </div>
            }/> 
   

          
          </Routes>
        </Router>
      </div>
      <Footer />
    </>
  );
}

export default App;



